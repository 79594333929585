body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.globalSidebar a, .globalSidebar a:hover, .globalSidebar a:focus { color:  darkslategrey ; text-decoration: none; }
 nav[class*="sidenav---expanded"].globalSidebar a { color: #fff;}
.fa, .fab, .fal, .far, .fas {
    line-height: 50px;
}

.globalSidebar div[class*="sidenav"]  div[class*="sidenav---sidenav-subnav"] > div[class*="sidenav---sidenav-subnavitem"] > div[class*="sidenav---navitem"] {
    line-height: 1.2;
    padding-top: 3px;
    padding-bottom: 3px;
}

nav[class*="sidenav---collapsed"].globalSidebar div[class*="sidenav"]  div[class*="sidenav---sidenav-subnav"] > div[class*="sidenav---selected"] > div[class*="sidenav---navitem"] {
     background: #eee;
}


nav[class*="sidenav---collapsed"].globalSidebar div[class*="sidenav"]  div[class*="sidenav---sidenav-subnav"] > div[class*="sidenav---selected"] > div[class*="sidenav---navitem"]:hover {
    background: #eee;
}

.globalSidebar div[class*="sidenav"] div[class*="sidenav---expanded"] div[class*="sidenav---sidenav-subnav"] > div[class*="sidenav---selected"] {
    background: transparent;
} 
.globalSidebar div[class*="sidenav---expanded"]  div[class*="sidenav---sidenav-subnav"] > div[class*="sidenav---sidenav-subnavitem"]::before {
   height: 99%;   
}

a {outline : none;}
